import React, { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../Context/AuthContext';
import { Modal } from '../Modal/Modal';


export const ButtonNavigator = ({ backLocation }) => {
  const { orderData, orderItems, setTotalPrice, totalQuantity } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showError, setShowError] = useState(false)

  const isBankInfoPage = location.pathname.includes('/bank-info');

  // Calculate totals using useMemo
  const { totalAmount, totalAmountWithTax, notRefundable, refundFee, totalRefundedAmount } = useMemo(() => {
    const initialValues = { priceSum: 0, taxSum: 0, discountSum: 0, totalAmountWithTax: 0, notRefundable: 0, refundFee: 0, totalRefundedAmount:0 };

    const totals = orderItems.reduce((acc, item) => {
      const quantity = parseFloat(item?.refund_quantity);
      const price = parseFloat(item?.Product?.price);
      const tax = parseFloat(item?.Product?.tax);
      const discount = parseFloat(item?.Product?.discount);

      acc.priceSum += price * quantity;
      acc.taxSum += tax * quantity;
      acc.discountSum += discount * quantity;

      return acc;
    }, initialValues);
    const shippingCost = parseFloat(orderData?.shipping_cost) || 0;
    const cashOnDelivery = parseFloat(orderData?.cash_on_delivery) || 0;
    const otherCostTax = (parseFloat(orderData?.tax_percentage) / 100) * (shippingCost + cashOnDelivery)
    const refundFee = 15;
    const priceWithTax = (totals.priceSum + totals.taxSum + shippingCost + cashOnDelivery + otherCostTax - totals.discountSum)
    let finalTotalPrice = (priceWithTax  - shippingCost - cashOnDelivery  -otherCostTax).toFixed(2);
    finalTotalPrice = Math.max(finalTotalPrice, 0);
    const notRefundable = (shippingCost + cashOnDelivery  +otherCostTax).toFixed(2)
    const totalRefundedAmount = finalTotalPrice - refundFee
    return {
      totalAmountWithTax: priceWithTax,
      totalDiscount: totals.discountSum.toFixed(2),
      totalAmount: finalTotalPrice,
      totalTax: (totals.taxSum).toFixed(2),
      orderTax: otherCostTax.toFixed(2),
      notRefundable: notRefundable,
      refundFee: refundFee,
      totalRefundedAmount: totalRefundedAmount
    };
  }, [orderItems, orderData]);

  const handleBackClick = () => navigate(backLocation);

  const handleFillBankDetailsClick = () => {
    setIsModalOpen(true)
    if(totalQuantity > 0) {
      setShowError(false)
    } else {
      setShowError(true)
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const handleNextClick = async () => {
    setTotalPrice(totalAmount > 0 ? totalAmount : 0);
    localStorage.setItem('orderData', JSON.stringify(orderData));
    localStorage.setItem('orderItems', JSON.stringify(orderItems));
    localStorage.setItem('totalPrice', totalAmount);
    localStorage.setItem('totalQuantity', totalQuantity);

    // const token = localStorage.getItem('token');
    // const returnItems = orderItems
    //             .filter(item => item.refund_quantity > 0)
    //             .map(item => ({
    //                 product_id: item.Product.id,
    //                 quantity: item.refund_quantity
    //             }));
    // try {
    //     const response = await fetch(`${apiUrl}/validate-refund-request`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`
    //         },
    //         body: JSON.stringify({ items: returnItems })
    //     });

    //     const result = await response.json();

    //     if (response.ok) {
    //       toast.success('Refund request is valid. Redirecting...');
    //       setTimeout(() => {
    //         navigate('/bank-info');
    //       }, 1000)  
    //     } else {
    //         toast.error(result.message || 'Validation failed');
    //     }
    // } catch (error) {
    //     console.error('Error:', error);
    //     toast.error('An error occurred while validating the refund request.');
    // }
    navigate('/bank-info', {
      state: {
        totalAmount,
        totalAmountWithTax,
        notRefundable,
        refundFee,
        totalRefundedAmount
      }
    });
};

  return (
    <>
    <div className="flex justify-center w-full mt-10">
      <div className={`flex w-full max-w-4xl bg-white justify-center`}>
        {!isBankInfoPage && (
          <button
            className={`flex text-center items-center bg-black text-white px-4 py-2 justify-center ${isBankInfoPage ? 'w-1/5' : 'w-2/5'}`}
            onClick={handleFillBankDetailsClick}
          >
            <p>{t('fillBankDetails')}</p>
          </button>
        )}
        {backLocation && (
          <button
            className="flex text-left items-center bg-black text-white px-4 py-2 w-1/5 space-x-2"
            onClick={handleBackClick}
          >
            <p>{t('back')}</p>
          </button>

        )}

      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} handleNextClick={handleNextClick} showNextButton={!showError}>
      {showError ? (
        <div className='text-red-900 text-center m-4'>
          {t('invalidQuantity')}
        </div>
      ): (
        <div className="p-4">
          <h2 className="text-xl text-right font-semibold mb-4">{t('receipt')}</h2>
          <div className="space-y-2">
            <DetailRow
              label={t('subtotalWithTax')}
              value={parseFloat(totalAmountWithTax).toFixed(2)}
            />
            {/* <DetailRow
        label={t('shippingAndCashOnDeliveryTax')}
        value={orderTax}
        /> */}
        <DetailRow
          label={t('totalRefundableAmount')}
          value={totalAmount}
        />
        <DetailRow
          label={t('totalNonRefundableAmount')}
          value={notRefundable}
        />
            <DetailRow
              label={t('refundFee')}
              value={refundFee}
              isBold
            />
            <DetailRow
          label={t('totalRefundedAmount')}
          value={totalRefundedAmount > 0 ? totalRefundedAmount : 0}
          borderTop
          isBold
        />
          </div>
        </div>
      )}
      </Modal>
    </div>
    </>
  );
};

const DetailRow = ({ label, value, isBold, borderTop }) => {
  // Format the value to two decimal points
  const formattedValue = typeof value === 'number' ? value.toFixed(2) : value;

  return (
    <div className={`flex justify-between ${isBold ? 'font-semibold' : ''} ${borderTop ? 'border-t pt-2' : ''}`}>
      <span>{formattedValue}</span>
      <span>{label}</span>
    </div>
  );
};
